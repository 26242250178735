import loadSlider from "./swiper.js";
import adjustElementSize from "./adjustElementSizes.js";

document.addEventListener('DOMContentLoaded', function() {
    const navLinks = document.querySelectorAll('.getTitle');
    const frontPageLink = Array.from(document.querySelectorAll('.frontPage'));
    const frontPage = document.getElementById('front-page');
    const bottomNavigation = document.querySelector('.bottomNavigation');
    const subpages = document.querySelectorAll('.sub--page-container');
    const loadSliderLinks = document.querySelectorAll('.loadSlider');
    const buttonHideScreenSaver = document.querySelectorAll('.hideScreenSaver');
    const screenSaver = document.querySelector('.screen--saver'); 
    const elementsToAnimate = document.querySelectorAll('.screen--saver .wrapper > *'); 


    const langEn=document.querySelector('.changeLangToEN')
    const langPl = document.querySelector('.changeLangToPL')

    if(langEn){
        langEn.onclick=()=>{
            document.body.classList.add('-enVersion')
            document.body.classList.remove('-plVersion')

            adjustElementSize()
        }
    }

    if(langPl){
        langPl.onclick=()=>{
            document.body.classList.remove('-enVersion')
            document.body.classList.add('-plVersion')

            adjustElementSize()
        }
    }

    const setActiveLink = (page) => {
        const activeLink = Array.from(document.querySelectorAll(`.bottomNavigation .link[data-page="${page}"]`));
        if (activeLink) {
            navLinks.forEach(link => {
                link.classList.remove('-active');
            });
            activeLink.map(link=>link.classList.add('-active'))
            // activeLink.classList.add('-active');
        }
    }

    const navigateToPage = (page) => {
        frontPage.classList.add('hidden');
        bottomNavigation.classList.remove('unvisible');
        bottomNavigation.classList.add('visible');

        subpages.forEach(subpage => {
            subpage.classList.remove('visible');
            subpage.classList.add('hidden');
        });

        setTimeout(() => {
            const activePage = document.querySelector(`.subpage${page.charAt(0).toUpperCase() + page.slice(1)}`);
            activePage.classList.remove('hidden');
            activePage.classList.add('visible');

            const existingSwiper = document.querySelector('.swiper--wrapper')
            if(existingSwiper){
                existingSwiper.parentNode.removeChild(existingSwiper);
            }
        }, 500);
    }

    navLinks.forEach(link => {
        link.addEventListener('click', event => {
            event.preventDefault();
            frontPage.classList.remove('visible');
            const page = event.currentTarget.getAttribute('data-page'); 
            navigateToPage(page);
            setActiveLink(page);
        });
    });
    frontPageLink.map(frontLink=>    frontLink.addEventListener('click', event => {
        event.preventDefault();
        frontPage.classList.add('visible');
        subpages.forEach(subpage => {
            subpage.classList.remove('visible');
            subpage.classList.add('hidden');
        });

        const existingSwiper = document.querySelector('.swiper--wrapper')
        if(existingSwiper){
            existingSwiper.parentNode.removeChild(existingSwiper);
        }

        frontPage.classList.remove('hidden');
        bottomNavigation.classList.remove('visible');
        bottomNavigation.classList.add('unvisible');
        setActiveLink(null);
    }))

    loadSliderLinks.forEach(link => {
        link.addEventListener('click', event => {
            event.preventDefault();
            loadSlider(event);
        });
    });

    buttonHideScreenSaver.forEach(button => {
      button.addEventListener('click', function() {
        elementsToAnimate.forEach((element, index) => {
          setTimeout(() => {
              element.classList.add('fade-out');
          }, index * 200); 
          setTimeout(() => {
            screenSaver.classList.add('unvisible');
            frontPage.classList.add('visible');
        }, 1500); 
        });
      });
    });

});

