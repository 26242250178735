document.addEventListener('mousemove', function(e) {
    const gradient = document.querySelector('.radialGradient');
    const x = e.clientX; 
    const y = e.clientY; 

    const gradientWidth = gradient.offsetWidth;
    const gradientHeight = gradient.offsetHeight * 2;

    const gradientCenterX = gradientWidth / 2;
    const gradientCenterY = gradientHeight / 2;

    const offsetX = (x - gradientCenterX) / 5;
    const offsetY = (y - gradientCenterY) / 5;

    gradient.style.backgroundPosition = `${offsetX}px ${offsetY}px`;
});