document.addEventListener('DOMContentLoaded', function() {
    const sliderNavigations = document.querySelectorAll('.slider--navigation'); 

    sliderNavigations.forEach(sliderNavigation => {


        let isDragging = false;
        let startX;
        let scrollLeft;

        sliderNavigation.addEventListener('mousedown', function(e) {
            isDragging = true;
            startX = e.pageX - sliderNavigation.getBoundingClientRect().left; 
            scrollLeft = sliderNavigation.scrollLeft;
            sliderNavigation.style.cursor = 'grabbing';
            sliderNavigation.style.userSelect = 'none'; 
        });

        sliderNavigation.addEventListener('mouseleave', function() {
            isDragging = false;
            sliderNavigation.style.cursor = 'grab';
        });

        sliderNavigation.addEventListener('mouseup', function() {
            isDragging = false;
            sliderNavigation.style.cursor = 'grab';
        });

        sliderNavigation.addEventListener('mousemove', function(e) {
            
            if (!isDragging) return;
            e.preventDefault();
            const x = e.pageX - sliderNavigation.getBoundingClientRect().left;
            const walk = (x - startX) * 2; 
            sliderNavigation.scrollLeft = scrollLeft - walk;
        });
    });
});