import Swiper from 'swiper';
import { Autoplay, EffectFade } from 'swiper/modules';

const imgSwiper=(divElement)=>{
    const swiper = new Swiper(divElement, {
            modules:[Autoplay,EffectFade],
            speed: 400,
            effect: 'fade',
            autoplay:{
                delay:4000
            },
            slidesPerView:1,
            loop:true
        });

        swiper.init()
}

export default imgSwiper