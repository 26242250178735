import Carousel from './Carousel.js';
import imgSwiper from './imgSwiper.js';

const loadSlider = (event) => {
    const clickedElement = event.currentTarget;
    const parentElement = clickedElement.closest('.element');
    const page = clickedElement.dataset.page;
    const sliderFilePath = `slider_${page}.html`;
    const existingSlider = document.querySelector('.swiper--wrapper');
    

    if (document.querySelector(`.swiper--wrapper[data-page="${page}"]`)) {
        return; 
    }
  
    if (existingSlider) {
        existingSlider.classList.add('fade-out');
        setTimeout(() => {
            existingSlider.parentNode.removeChild(existingSlider);
        }, 500);   
    }

    fetch(sliderFilePath)
        .then(response => response.text())
        .then(html => {
            const sliderContainer = document.createElement('li');
            sliderContainer.classList.add('swiper--wrapper');
            sliderContainer.setAttribute('data-page', page); 
            sliderContainer.innerHTML = html;
            parentElement.insertAdjacentElement('afterend', sliderContainer);

            const newCarousel = new Carousel(sliderContainer.querySelector('.swiper'));
            newCarousel.init();

            const imgSliders = Array.from(sliderContainer.querySelectorAll('.imgSwiper'))
            imgSliders.map(divElement=>{
                imgSwiper(divElement)
            })

            const swiperClose = sliderContainer.querySelector('.swiper-close');
            swiperClose.addEventListener('click', () => {
                sliderContainer.classList.add('fade-out');
                setTimeout(() => {
                    sliderContainer.remove();
                }, 500);
            })
        })
        .catch(error => console.error('Error loading the slider:', error));
};


export default loadSlider;
