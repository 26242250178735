const adjustElementSize=()=>{
    const elements = document.querySelectorAll('.adjustElementSizes');
    elements.forEach(element => {
        const link = element.querySelector('.link'); 
        const linkWidth = parseFloat(window.getComputedStyle(link).width); 
        const linkHeight = parseFloat(window.getComputedStyle(link).height); 

        const newDimension = Math.max(linkWidth, linkHeight);

        element.style.width = newDimension * 1.3 + 'px';
        element.style.height = newDimension * 1.3 + 'px';
    });
}

export  default adjustElementSize;

